<template>
    <div>
        <nav-admin></nav-admin>
         <div class="container">
            <div class="row mt-4 mb-2">
                <div class="col-4">
                    <router-link class="btn btn-primary" :to="{ name: 'EventEditor',  params: { event_id: eventId }}" >Back</router-link>
                </div>
                <div class="col-8">
                    <span class="float-right">
                        <b-button v-if="isCustomersValid" variant="primary"  @click="updateEvent">Next</b-button>
                        <b-button v-if="!isCustomersValid" disabled variant="primary">Next</b-button>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form-checkbox-group
                        v-model="checkedTableFields"
                        :aria-describedby="ariaDescribedby"
                    >
                        <b-form-checkbox value="country">Country</b-form-checkbox>
                        <b-form-checkbox value="spent_money_total">Paid amount</b-form-checkbox>
                        <b-form-checkbox value="completed_orders_total">Completed</b-form-checkbox>
                        <b-form-checkbox value="in_progress_orders_total">In progress</b-form-checkbox>
                        <b-form-checkbox value="last_order_date">Order date</b-form-checkbox>
                    </b-form-checkbox-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">

                    <loading v-if="isEventCustomersLoading" />

                    <div class="spiner-wrapper" v-if="getEventCustomersTotal == 0 && !isEventCustomersLoading">
                        <b>Nothing found</b>
                    </div>
                    
                    <div class="event-customers-font-size">
                        <b-table
                            v-if="!isEventCustomersLoading && getEventCustomersTotal > 0"
                            id="eventsCustomers"
                            :items="getEventCustomers"
                            :fields="fields"
                            :per-page="getEventCustomersTotal"
                            :current-page="1"
                            :small="true"
                            :fixed="true"
                            :bordered="true"
                        >        
                            <template #table-colgroup="scope">
                                <col
                                    v-for="field in scope.fields"
                                    :key="field.key"
                                    :style="{ width: field.key === 'controls' ? '30px' : 'auto' }"
                                >
                            </template>
                            <template v-slot:head(controls)>
                                <span class="float-left">
                                    <b-form-checkbox-group
                                        v-model="allCheckField"
                                        :aria-describedby="ariaDescribedby"
                                    >
                                        <b-form-checkbox  :value="true"></b-form-checkbox>
                                    </b-form-checkbox-group>
                                </span>
                            </template>
                            <template v-slot:cell(controls)="data">
                                <span class="float-left">
                                    <b-form-checkbox-group
                                        v-model="data.item.checked"
                                        :aria-describedby="ariaDescribedby"
                                    >
                                        <b-form-checkbox @change="checkboxChange" value="true"></b-form-checkbox>
                                    </b-form-checkbox-group>
                                </span>
                            </template>

                            <template v-slot:cell(full_name)="data">
                                {{ data.item.full_name }}
                            </template>

                            <template v-slot:cell(email)="data">
                                {{ data.item.email }}
                            </template>

                            <template v-slot:cell(country)="data">
                                {{ data.item.country }}
                            </template>

                            <template v-slot:cell(spent_money_total)="data">
                                {{ data.item.spent_money_total ? '$' + data.item.spent_money_total : '' }}
                            </template>
                            
                            <template v-slot:cell(completed_orders_total)="data">
                                {{ data.item.completed_orders_total }}
                            </template>

                            <template v-slot:cell(in_progress_orders_total)="data">
                                {{ data.item.in_progress_orders_total }}
                            </template>

                            <template v-slot:cell(last_order_date)="data">
                                {{ data.item.last_order_date ? convertBrouserTZDateOnly(data.item.last_order_date) : '' }}
                            </template>

                            <template v-slot:cell(error_info)="data">
                                <button v-if="!data.item.is_valid" @click="showError(data.item.invalid_fields)" class="btn btn-danger float-right btn-sm">Info</button>
                            </template>

                        </b-table>
                    </div>
                </div>
            </div>
         </div>

        <b-modal id="templateErrorPopup" title="Template error">
            <b-container fluid>
                <div>
                    Can't insert <b>{{ errorFields }}</b>. <br />
                    Please uncheck higligted customers or <router-link :to="{ name: 'EventEditor',  params: { event_id: eventId }}" >update template.</router-link>
                </div>
            </b-container>    
            <template v-slot:modal-footer="{ cancel }">
                <b-button @click="cancel()">Cancel</b-button>
            </template>
        </b-modal>  

    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import loading from './../../components/admin/spiner';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { timeZone } from '../../mixins/time-zone.mixin';
export default {
    mixins: [timeZone],
    components: {
        navAdmin,
        loading
    },
    computed: {
        ...mapGetters([
            'isEventCustomersLoading',
            'getEventCustomersTotal',
            'getEventCustomers',
            'getEvent'
        ]),
        allCheckField: {
            get() {
                for(let i = 0; i < this.getEventCustomers.length; i++) {
                     if(!this.getEventCustomers[i].checked) {
                        return false;
                    }
                }
                return true;
            },
            set(val) {
                this.groupEventCustomersCheckedUpdate(val);
            }
        },
        eventId() {
            return this.$route.params.event_id;
        },
        fields() {
            const res = [];

            for(let i = 0; i < this.fieldsRessourses.length; i++) {
                if(this.checkedTableFields.includes(this.fieldsRessourses[i].key)) {
                    res.push(this.fieldsRessourses[i]);
                }
            }

            const customers = this.getEventCustomers;

            for(let i = 0; i < customers.length; i++) {
                if(!customers[i].is_valid) {
                    
                    res.push(this.fieldsRessourses[this.fieldsRessourses.length - 1]);
                    
                    break;
                }
            }

            return res;
        }
    },

    data() {
        return {
            isCustomersValid: true,
            errorFields: null,
            checkedTableFields: [
                'controls',
                'full_name',
                'email'
            ],
            fieldsRessourses: [
                { key: 'controls', label: `` },
                { key: 'full_name', label: "Full name", sortable: true },
                { key: 'email', label: "Email", sortable: true },
                { key: 'country', label: "Country", sortable: true },
                { key: 'spent_money_total', label: "Paid amount", sortable: true },
                { key: 'completed_orders_total', label: "Completed", sortable: true },
                { key: 'in_progress_orders_total', label: "In progress", sortable: true },
                { key: 'last_order_date', label: `Order date`, sortable: true },
                { key: 'error_info', label: `` },
            ]
        }
    },
    methods: {
        ...mapMutations(['removeCustomerFromEventList', 'groupEventCustomersCheckedUpdate']),
        ...mapActions(['createEventAction', 'getEventAction', 'updatedEventAction']),
        checkboxChange() {
            const customers = this.getEventCustomers;

            if(customers.filter(x => x.checked == true).length == 0)
                return false;

            for(let i = 0; i < customers.length; i++) {
                if(!customers[i].is_valid && customers[i].checked) {
                    this.isCustomersValid = false;
                    return;
                }
            }

            this.isCustomersValid = true;
        },
        async createEvent() {
            await this.createEventAction();
            this.$router.push({ 
                name: 'EventEditor',
                params: { 'event_id': this.eventId }
            });
        },
        async updateEvent() {
            await this.updatedEventAction();

            this.$router.push({ 
                name: 'MarketingTemplatePreview', 
                params: { 'event_id': this.eventId }
            });
        },
        showError(error_fields) {
            
            this.errorFields = error_fields.split(',').join(', ');
            this.$bvModal.show('templateErrorPopup');
        }
    },
    async mounted() {
        await this.getEventAction(this.eventId);
        this.checkboxChange();
    }
}
</script>
<style scoped>
    .event-customers-font-size {
        font-size: 13px;
    }
</style>
